@import "../../global-styles/terra.scss";

.mep-404-page {
  &--loading {
    align-self: center;
  }

  .ter-hero-five {
    &__image-container {
      @media (min-width: $breakpoint-sm) {
        @include grid-column(6);
      }
    }

    &__image {
      width: auto;
      max-height: 500px;

      height: auto;
      display: block;
      margin: auto;

      @media (max-width: 900px) {
        padding-top: 48px;
      }
    }
  }
}
