@import "../../global-styles/global-vars.scss";

.mep-support-html-cards {
  padding: spacing("super") calc((100vw - 1200px) / 2);

  @media screen and (max-width: 1272px) {
    padding: spacing("super") 36px;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media screen and (max-width: 900px) {
    display: block;
  }
}

.mep-support-html-card {
  margin: 0 0 32px;
  width: 500px;
  padding: 32px;
  background: color("gray-3");
  align-self: stretch;

  // These styles were commented out to meet Sean's requirements
  // It looks like this was originally designed to be an organism with only 2 cards but has since evolved to hold a bunch of them

  // flex: 1;

  // &:first-child {
  //   margin-right: 16px;
  // }

  // &:last-child {
  //   margin-left: 16px;
  // }

  ul {
    margin: 0;
  }

  @media screen and (max-width: 900px) {
    margin: 0 auto 32px;
    align-self: start;

    &:first-child {
      margin: 0 auto 32px;
    }

    &:last-child {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 600px) {
    width: auto;
    padding: 16px;

    p,
    li {
      font-size: 16px;
    }
  }

  p {
    margin: 0;
  }
}
