@import "../../global-styles/global-terra.scss";

.luna-page-builder {
  .ter-feat-one,
  .ter-feat-two,
  .ter-feat-three,
  .ter-feat-four,
  .ter-feat-five,
  .ter-feat-six,
  .ter-feat-seven,
  .ter-feat-eight .ter-feat9,
  .luna-video-feat,
  .ter-hero-four,
  .ter-hero-five {
    max-width: 1200px;
    margin: auto;
  }

  .ter-feat-eight {
    &__card-container {
      .ter-card {
        &__body {
          .ter-button {
            padding: 0;
          }
        }
      }
    }
  }

  .ter-feat-four-flex {
    .ter-card {
      color: color("gray-7") !important;
    }
  }

  .ter-feat-three {
    .ter-icon-list-item {
      &__header {
        @media screen and (max-width: 400px) {
          font-size: 20px;
        }
      }

      p {
        @media screen and (max-width: 400px) {
          font-size: 16px;
        }
      }
    }
  }

  .ter-feat-ten {
    @media screen and (max-width: 600px) {
      padding-top: 32px !important;
    }

    &__header {
      padding: 0 32px 16px;
    }
  }

  .ter-tabs-panel {
    .ter-icon-list-item {
      max-width: 500px;
      // min-width: 300px;
      margin: 0 auto;

      @media screen and (max-width: 1000px) {
        width: 100%;
        max-width: 100%;
      }

      &__header {
        @media screen and (max-width: 400px) {
          font-size: 20px;
        }
      }

      p {
        @media screen and (max-width: 400px) {
          font-size: 16px;
        }
      }
    }

    &__wrapper {
      padding-top: 72px;
      padding-bottom: 72px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      @media screen and (max-width: 1000px) {
        width: 80%;

        padding-top: 32px;
        padding-bottom: 32px;
      }

      @media screen and (max-width: 900px) {
        display: block;
      }

      @media screen and (max-width: 500px) {
        margin: 0 auto;
        width: 100%;
        padding: 0px 16px;
      }
    }
  }

  .ter-feat-four-flex__background-image,
  .ter-feat-eight__background-image,
  .ter-feat-six__background-image,
  .ter-feat-one__background-image,
  .luna-video-feat__background-image {
    // opacity: 0.2;
  }

  .custom-feat-mep {
    margin-top: 96px;
    @media screen and (max-width: 1200px) {
      margin: 32px;
    }
  }
  .custom-feat {
    margin: 0 auto;
    width: 500px;
    padding: 32px;
    background: color("gray-3");
    align-self: stretch;
      
      ul {
        margin: 0;
      }

      @media screen and (max-width: 900px) {
        margin: 0 auto 32px;
        align-self: start;

        &:first-child {
          margin: 0 auto 32px;
        }

        &:last-child {
          margin: 0 auto;
        }
      }

      @media screen and (max-width: 600px) {
        width: auto;
        padding: 16px;

        p,
        li {
          font-size: 16px;
        }
      }

      p {
        margin: 0;
      }
  }

  .custom-feat-mep-full-width {
    .custom-feat {
      width: auto;
    }
  }
  
}
