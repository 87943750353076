.mep-admin-home-page {
  margin-top: 36px;
  padding: spacing("super") calc((100vw - 1200px) / 2);

  @media screen and (max-width: 1272px) {
    padding: spacing("super") 36px;
  }

  h2 {
    padding-top: 50px;
    text-align: center;
  }

  p {
    text-align: center;
    max-width: 800px;
    margin: auto;
    padding-top: 16px;
  }

  &__links {
    padding-top: 50px;
    padding-bottom: 90px;
    max-width: 600px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .ter-button {
      width: 150px;

      justify-content: center;
    }
  }
}
