/**
 * Width
 */

.width-auto { width: auto; }

.width-25 { width: 25%; }
.width-50 { width: 50%; }
.width-75 { width: 75%; }
.width-100 { width: 100%; }

.width-10 { width: 10%; }
.width-20 { width: 20%; }
.width-30 { width: 30%; }
.width-40 { width: 40%; }
.width-60 { width: 60%; }
.width-70 { width: 70%; }
.width-80 { width: 80%; }
.width-90 { width: 90%; }


@media only screen and (min-width: $breakpoint-xs) {

	.width-auto-sm { width: auto; }

}

@media only screen and (min-width: $breakpoint-sm) {

	.width-auto-md { width: auto; }

}

@media only screen and (min-width: $breakpoint-md) {

	.width-auto-lg { width: auto; }

}