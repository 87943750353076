@import "../../global-styles/global-vars.scss";

$link-tile-border: 1px solid color("gray-3");

.mep-support-link-tiles {
  padding: spacing("super") calc((100vw - 1200px) / 2);

  @media screen and (max-width: 1272px) {
    padding: spacing("super") 36px;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media screen and (max-width: 1200px) {
    display: block;
  }

  .mep-link-tile {
    align-self: stretch;
    margin: 16px 0;
    display: grid;
    grid-template-columns: 1fr 48px;
    width: 500px;
    padding: 24px;
    border-top: $link-tile-border;
    border-bottom: $link-tile-border;
    align-self: stretch;
    text-decoration: none;

    @media screen and (max-width: 1200px) {
      margin: 32px auto;
      max-width: 700px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 1200px) {
      width: auto;
    }

    &__header,
    &__text {
      color: color("gray-7");
    }

    &__text {
      margin: 0;

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }

    .ter-icon {
      margin: 0 16px;
      align-self: center;
      display: block;
    }
  }
}
