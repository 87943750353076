@import "../../global-styles/terra.scss";

.mep-collections-page {
  &--loading {
    align-self: center;
  }

  .ter-filter-hero {
    &__header-section {
      @media screen and (max-width: 600px) {
        padding: 64px;
        width: auto;
        max-width: auto;
      }

      @media screen and (max-width: 400px) {
        padding: 32px;
      }
    }
    &__dropdown-container {
      @media screen and (max-width: 750px) {
        display: block;
      }

      @media screen and (max-width: 600px) {
        margin-left: 64px;
      }

      @media screen and (max-width: 400px) {
        margin-left: 32px;
      }
    }
  }

  .ter-card {
    &__text {
      color: color("gray-7");
    }
    .ter-button {
      // height: 32px;
      // line-height: 32px;
    }
  }

  .ter-dropdown__options-list {
    overflow-x: hidden;
  }
}
