.mep-error-page {
  .ter-hero-five {
    &__image {
      height: 500px !important;
      width: 500px !important;
      display: block;
      margin-left: auto;
    }

    &__image-container {
      grid-column: span 6;
    }

    &__content-container {
      grid-column: span 6;
    }
  }
}
// TODO ErrorPage - This is placeholder styling - remove before deploying
