@import "../../global-styles/terra.scss";

.mep-success-page {
  padding: spacing("super") calc((100vw - 1200px) / 2) !important;

  @media screen and (max-width: 1272px) {
    padding: spacing("super") 36px !important;
  }

  @media screen and (max-width: 600px) {
    padding: spacing("super") 16px !important;
  }

	&--loading {
    	align-self: center;
  	}	
  &__container {
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 375px;
    padding: 48px 32px;
    width: 1080px;
    width: auto;
    min-height: 200px;
    background-color: #EAEAEF;

    @media screen and (max-width: 900px) {
      display: block;
      height: auto;
    }
  }

  &__header {
    grid-column: 1 / span 1;
    font-size: 28px;
    line-height: 25px;
  }

  &__subHeader {
    grid-column: 1 / span 1;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  .button-success-page--button-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 900px) {
      justify-content: flex-start;

      .ter-button {
        &:last-child{
          margin-left: 16px;
        }
      }
    }

    @media screen and (max-width: 409px) {
      display: block;
      .ter-button {
        &:last-child{
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }

    .ter-button {
      display: inline-block;
      width: 157px;
      height: 48px;
    }
  }


  .ter-button--success-page {
    justify-self: end;
    align-self: center;
    display: inline-block;
    width: 157px;

    @media screen and (max-width: 900px) {
      margin-top: 16px;
    }
  
  }

}

