.mep-page-builder-page {
  &--loading {
    align-self: center;
  }

  .ter-hero-one {
    padding-bottom: 8px;
  }

  .custom-feat {
  	margin-bottom: 32px;
  }
}
