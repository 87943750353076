.mep-demo-page {
  width: 600px;
  padding: 16px 0 32px;
  margin: auto;

  &__mkto-form {
    display: none;
  }

  &--loading {
    align-self: center;
  }

  &__submitting-img-wrapper {
    position: relative;
  }

  &__submitting-img {
    position: absolute;
    left: 150px;
    top: -35px;
  }

  &__header {
    padding: 36px 36px 16px 16px;
  }

  &__subText {
    padding: 0px 36px 16px 16px;
  }

  .ter-search-select__options-list {
    z-index: 100;
  }

  .luna-marketo-form {
    padding: 0 36px;
  }

  .ter-search-select {
    margin-bottom: 24px;
  }

  .ter-checkbox {
    padding-top: 0;
  }

  .luna-marketo-form__submission-agreement-text {
    padding-top: 0;
    padding-bottom: 16px;
    font-size: 16px;
  }

  &__submitting-img {
    display: block;
    margin: auto;
    position: relative;
    left: 0;
    top: 0;

    &-wrapper {
      padding: 40px 0;
    }
  }
}
