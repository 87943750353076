/**
 * Background
 */

@each $name, $hex in $color-palette {
    .bg-#{$name} {
        background-color: $hex;
    }
}

.bg-transparent {
	background-color: transparent;
}