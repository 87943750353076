@import "../../global-styles/global-terra.scss";

.app {
  padding-top: 148px;
  background: color("gray-1");
  // border-top: solid 148px color("brand-2");
  min-height: 100vh;
  display: grid;
  align-items: start;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    // border-top: solid 60px color("brand-2");
    padding-top: 60px;
  }

  .luna-language-selector {
    overflow-y: scroll;

    @media screen and (max-width: 550px) {
      padding: 25px;
      overflow: scroll !important;
      max-height: 700px;
      // moves the selector down so that it doesn't peak up above the window in a non-scrollable area

      &__bottom-section {
        // TODO - when more regions become avail, uncomment the padding-top
        // the save/cancel buttons were defaulting to be in the middle of the region switcher, overlayed ontop of text. this feels hacky but it pushes the btns to where they need to be. I tried oher solutions but none worked.
        // padding-top: 900px;
      }
    }
  }

  .ter-alert {
    position: fixed;
    background: #ffec3f;
    z-index: 300;
    width: 100vw;
    margin: 0;
    bottom: 0;
  }

  .ter-hero-one {
    font-size: 20px;
    &__image--desktop {
      background-position: center;
    }
  }

  .ter-footer {
    // margin-top: auto;
    position: relative;
    z-index: 50;
    animation: fade-in 0.4s;
    align-self: end;
  }

  .ter-feat-four {
    padding-top: 96px;
    padding-bottom: 66px;
    &__header {
      padding: 0 36px;
    }
  }

  THIS HAS BEEN ADDED TO LUNA SASS .ter-feat-one,
  .ter-feat-two,
  .ter-feat-three,
  .ter-feat-four,
  .ter-feat-five,
  .ter-feat-six,
  .ter-feat-seven,
  .ter-feat-eight,
  .ter-feat9,
  .ter-feat-ten,
  .ter-hero-two,
  .ter-hero-four,
  .ter-hero-five,
  .ter-feat-four-flex {
    &__background-image {
      // transition: 0.1s;
      background-size: contain;
      // This is potentially bad for parallax performance optmization

      // background-repeat: no-repeat;
    }
    // overflow: hidden;
  }

  .ter-feat-one,
  .ter-feat-two,
  .ter-feat-three,
  .ter-feat-four,
  .ter-feat-five,
  .ter-feat-six,
  .ter-feat-seven,
  .ter-feat-eight,
  .ter-feat9,
  .ter-feat-ten,
  .ter-hero-two,
  .ter-hero-four,
  .ter-hero-five,
  .ter-feat-four-flex,
  .ter-hero-one,
  .ter-hero-two,
  .ter-hero-three,
  .ter-hero-four,
  .ter-hero-five,
  .ter-hero-six,
  .luna-video-feat {
    &__header {
      @include opensan-very-bold();
    }
  }

  .ter-navbar__fullscreen-dark-backer {
    top: 0;
  }

  .ter-feat-ten {
    &__sub-header {
      margin: auto;
      max-width: 1200px;
    }
    // padding-top: 96px !important;
    @media screen and (max-width: 600px) {
      // padding-top: 0;
    }
  }

  .ter-hero-one {
    min-height: 600px;

    &__text {
      font-size: 20px;
    }

    padding-bottom: 4px;
    @media screen and (max-width: 900px) {
      min-height: 500px;
    }
  }

  .ter-feat-one {
    &__sub-header {
      line-height: 1.6;
    }
  }

  .ter-tabs__content {
    .ter-icon-list-item {
      // margin: 0;
      min-width: 500px;

      @media screen and (max-width: 650px) {
        min-width: auto;
      }
    }
  }

  .ter-footer {
    &__middle-section-wrapper {
      padding-bottom: 32px;

      @media screen and (max-width: 600px) {
        padding-bottom: 0px;
      }

      a {
        text-decoration: none;
      }
    }

    &__secondary-link-label {
      font-size: 12px;
      padding-bottom: 16px;
      @media screen and (max-width: 600px) {
        margin-top: 16px;
        padding-bottom: 32px;
      }
    }

    &__link-containers {
      @media screen and (max-width: 600px) {
      }

      @media screen and (max-width: 400px) {
        .ter-footer__link {
          font-size: 14px;
        }
      }
    }

    &__social-links-container {
      @media screen and (max-width: 900px) {
        padding-top: 32px;
      }

      @media screen and (max-width: 720px) {
        padding-top: 0;
      }
    }

    &__legal-stuff {
      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
      }
    }
  }

  .luna-video-feat {
    &__video-wrapper {
      @media screen and (min-width: 1400px) {
        padding: 0 0 650px;
        width: 1200px;
        margin: 0 auto;
      }
    }
  }

  .ter-feat-ten {
    &__header {
      padding-bottom: 0;
    }
  }

  .ter-navbar-top {
    .luna-nav-search__input {
      height: 36px;
    }
  }

  .ter-accordion {
    &__content {
      p {
        margin: 0;
      }
      &--active {
        padding-top: 0 !important;
      }
    }
  }
}

.luna-marketo-form__bottom-text {
  padding-top: 16px;
  margin: 0;
  font-size: 16px;
}

#addToCartButton {
  display: none;
}

.ter-tabs {
  &--theme-4,
  &--theme-5 {
    a {
      color: #f2f2f2;
    }
  }
}

.ter-navbar-top__search-wrapper {
  display: none;
} 

