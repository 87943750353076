@import "../../global-styles/terra.scss";

.mep-admin-page {
  padding: 0 calc((100vw - 1200px) / 2) 48px;
  margin-top: 48px;

  @media screen and (max-width: 1272px) {
    padding: 0 36px;
  }

  &__header {
    font-size: 30px;
    padding-bottom: 36px;
  }

  &__radios {
    .ter-button {
      height: 30px;
      line-height: 30px;
    }
    margin-bottom: 48px;
  }

  .ter-radio-group {
    padding-top: 0;
    padding-bottom: 8px;
    display: flex;
  }

  .ter-form-item {
    padding-bottom: 0;
  }

  &__lang-filter-label {
    margin: 0;
    font-size: 16px;
    padding-top: 48px;
  }

  &__prod-section-label {
    margin: 16px 0 0;
    padding-bottom: 8px;
    font-size: 20px;
    @include opensan-very-bold();
  }

  .ter-radio {
    margin-left: 48px;
    padding-top: 0;

    &:first-child {
      margin-left: 0;
    }
  }
}
