@import "../../global-styles/terra.scss";

.mep-search-result-page {
  &--loading {
    align-self: center;
  }

  padding: 0 calc((100vw - 1200px) / 2);

  @media screen and (max-width: 1272px) {
    padding: 0 36px;
  }

  @media screen and (max-width: 400px) {
    padding: 0;
  }

  width: 100%;
  margin: 0 auto;
  overflow: scroll;
  background: color("gray-1");

  &__text {
    padding: 32px 0 0 32px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .ter-hero-five {
    &__image-container {
      @media (min-width: $breakpoint-sm) {
        @include grid-column(6);
      }
    }

    &__image {
      width: auto;
      max-height: 500px;

      height: auto;
      display: block;
      margin: auto;

      @media (max-width: 900px) {
        padding-top: 48px;
      }
    }
  }

  .ter-feat-four-flex {
    @media screen and (max-width: 600px) {
      padding: 32px 0;
      margin: auto;
    }
  }
}
