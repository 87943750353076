/**
 * Text Align
 */

.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }

@media only screen and (min-width: $breakpoint-xs) {
	.text-sm-left { text-align: left; }
	.text-sm-right { text-align: right; }
	.text-sm-center { text-align: center; }
}

@media only screen and (min-width: $breakpoint-sm) {
	.text-md-left { text-align: left; }
	.text-md-right { text-align: right; }
	.text-md-center { text-align: center; }
}

@media only screen and (min-width: $breakpoint-md) {
	.text-lg-left { text-align: left; }
	.text-lg-right { text-align: right; }
	.text-lg-center { text-align: center; }
}