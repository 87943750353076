@import "../../global-styles//global-vars.scss";

.mep-support-page {
  &--loading {
    align-self: center;
  }
  .ter-hero-five {
    &__image {
      display: none;
      &-container {
        display: none;
      }
    }
    &__content-container {
      padding: 182px 24px 96px;
    }
  }

  background: color("gray-1");
  &--loading {
    align-self: center;
  }

  .mep-support-link-tiles {
    background: color("gray-2");

    .mep-link-tile {
      border-top: solid 1px color("gray-4");
      border-bottom: solid 1px color("gray-4");
    }
  }

  .mep-support-html-cards {
    background: color("gray-1");
  }

  .ter-feat-eight {
    &__card-container {
      .ter-button {
        padding: 0 32px;
      }
    }
  }
}
