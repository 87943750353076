/**
 * Height
 */

// Height Percentages - Based off of height of parent

.height-25 {
    height: 25%;
}

.height-50 {
    height: 50%;
}

.height-75 {
    height: 75%;
}

.height-100 {
    height: 100%;
}

.min-height-100 {
    min-height: 100%;
}

// Screen Height Percentage

.vh-25 {
    height: 25vh;
}

.vh-50 {
    height: 50vh;
}

.vh-75 {
    height: 75vh;
}

.vh-100 {
    height: 100vh;
}

.min-vh-100 {
    min-height: 100vh;
}